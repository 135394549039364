import styled from "styled-components";

export const AboutContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 10vh;
  position: relative;
`;

export const ContentContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5vh;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ImageContainer = styled.div`
  height: 100%;
  width: 100%;
  flex: 0.4;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
`;

export const MissionContainer = styled.div`
  height: 100%;
  width: 100%;
  flex: 0.575;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`;

export const Details = styled.p`
  margin-top: 5vh;
  font-size: 1.2rem;
  font-family: "Aspira Regular", Arial, Helvetica, sans-serif;
`;

export const MapFrame = styled.iframe`
  outline: none;
  border: none;
  margin-top: 5vh;
  width: 80%;
  height: 35vh;
  border-radius: 20px;
  align-self: center;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const ContactContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 12vh;
  margin-bottom: 5vh;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;
