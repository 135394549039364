import React, { useState } from "react";
import { ScreenContainer, ContentContainer } from "./Elements";
import BackgroundSlider from "components/custom/BackgroundSlider";
import { bgcm1, bgcm2, bgcm3 } from "helpers/assets";
import { StepperTransition } from "helpers/transitions";
import { AnimatePresence } from "framer-motion";
import SectionContent from "components/custom/SectionContent";
const BACKGROUNDS = [bgcm1, bgcm2, bgcm3];

const SteemSection = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <ScreenContainer id="steems" className="container">
      <ContentContainer>
        <AnimatePresence mode="wait" key="steem">
          {activeIndex === 0 && (
            <SectionContent
              key="BGCM-FIRST"
              title="BGCM"
              description="Block Game Change Makers (BGCM)™ is a John Wall Family Foundation (JWFF) program that aims to introduce young people to concepts in science, technology, engineering, entrepreneurship, and math (STEEM)™ through interesting topics such as esports, robotics, and space. The program is designed to be engaging and interactive, using hands-on activities and real-world examples to help students understand complex concepts. For example, students in the program might work on projects related to designing and building robots or creating their own video games. The program also exposes students to various career paths in STEM fields and provides mentorship opportunities with industry professionals. Overall, BGCM™ aim™s to inspire and educate the next generation of STEEM™ leaders and innovators."
              cta="Volunteer"
              href="https://calendly.com/jwff"
              {...StepperTransition}
            />
          )}

          {activeIndex === 1 && (
            <SectionContent
              key="BGCM-SECOND"
              title="BGCM"
              description="Block Game Change Makers (BGCM)™ is a John Wall Family Foundation (JWFF) program that aims to introduce young people to concepts in science, technology, engineering, entrepreneurship, and math (STEEM)™ through interesting topics such as esports, robotics, and space. The program is designed to be engaging and interactive, using hands-on activities and real-world examples to help students understand complex concepts. For example, students in the program might work on projects related to designing and building robots or creating their own video games. The program also exposes students to various career paths in STEM fields and provides mentorship opportunities with industry professionals. Overall, BGCM™ aim™s to inspire and educate the next generation of STEEM™ leaders and innovators."
              cta="Volunteer"
              href="https://calendly.com/jwff"
              {...StepperTransition}
            />
          )}
          {activeIndex === 2 && (
            <SectionContent
              key="BGCM-THIRD"
              title="BGCM"
              description="Block Game Change Makers (BGCM)™ is a John Wall Family Foundation (JWFF) program that aims to introduce young people to concepts in science, technology, engineering, entrepreneurship, and math (STEEM)™ through interesting topics such as esports, robotics, and space. The program is designed to be engaging and interactive, using hands-on activities and real-world examples to help students understand complex concepts. For example, students in the program might work on projects related to designing and building robots or creating their own video games. The program also exposes students to various career paths in STEM fields and provides mentorship opportunities with industry professionals. Overall, BGCM™ aim™s to inspire and educate the next generation of STEEM™ leaders and innovators."
              cta="Volunteer"
              href="https://calendly.com/jwff"
              {...StepperTransition}
            />
          )}
        </AnimatePresence>
      </ContentContainer>
      <BackgroundSlider
        backgrounds={BACKGROUNDS}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        from="STEEM"
      />
    </ScreenContainer>
  );
};

export default SteemSection;
