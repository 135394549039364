import React, { useState } from "react";
import { HeroContainer, ContentContainer } from "./Elements";
import BackgroundSlider from "components/custom/BackgroundSlider";
import { hero1, hero2, hero3 } from "helpers/assets";
import { AnimatePresence } from "framer-motion";
import SectionContent from "components/custom/SectionContent";
import { StepperTransition } from "helpers/transitions";
const BACKGROUNDS = [hero1, hero2, hero3];

const HeroSection = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <HeroContainer id="home" className="container">
      <ContentContainer>
        <AnimatePresence mode="wait" key="HERO">
          {activeIndex === 0 && (
            <SectionContent
              key="First-Hero"
              title="Mission"
              description="The John Wall Family Foundation strives to improve the quality of life for disadvantaged families and impact the lives of at-risk youth through mentorship, education and physical activity."
              cta="Donate"
              to="donate"
              href="https://www.google.com"
              {...StepperTransition}
            />
          )}

          {activeIndex === 1 && (
            <SectionContent
              key="Second-Hero"
              title="Mission"
              description="The John Wall Family Foundation strives to improve the quality of life for disadvantaged families and impact the lives of at-risk youth through mentorship, education and physical activity."
              cta="Donate"
              to="donate"
              href="https://www.google.com"
              {...StepperTransition}
            />
          )}

          {activeIndex === 2 && (
            <SectionContent
              key="Third-Hero"
              title="Mission"
              description="The John Wall Family Foundation strives to improve the quality of life for disadvantaged families and impact the lives of at-risk youth through mentorship, education and physical activity."
              cta="Donate"
              to="donate"
              href="https://www.google.com"
              {...StepperTransition}
            />
          )}
        </AnimatePresence>
      </ContentContainer>

      <BackgroundSlider
        backgrounds={BACKGROUNDS}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        from="Hero"
      />
    </HeroContainer>
  );
};

export default HeroSection;

//     <HeroContainer id="home" className={`container`}>
//       <BackgroundSlider backgrounds={BACKGROUNDS} />
//       <TextSection
//         title="Mission"
//         description="The John Wall Family Foundation strives to improve the quality of life for disadvantaged families and impact the lives of at-risk youth through mentorship, education and physical activity."
//         cta="Donate"
//       />

//     </HeroContainer>
