import React from "react";
import {
  ContentContainer,
  SectionTitle,
  SectionDescription,
  SectionCta,
  StepListContainer,
  StepList,
  SectionLink,
} from "./Elements";
const SectionContent = ({
  title = "",
  description = "",
  cta = "",
  list = [],
  href = "",
  to,
  ...rest
}) => {
  return (
    <ContentContainer {...rest}>
      <SectionTitle>{title}</SectionTitle>
      {description && <SectionDescription>{description}</SectionDescription>}
      {list.length > 0 && (
        <StepListContainer>
          {list?.map((step, i) => (
            <>
              <StepList key={`${step}-${i}`}>{step}</StepList>
            </>
          ))}
        </StepListContainer>
      )}
      {to ? (
        <SectionLink to={to} smooth spy={true} offset={-50}>
          {cta}
        </SectionLink>
      ) : (
        <SectionCta href={href} target="_blank" referrerPolicy="no-referrer">
          {cta}
        </SectionCta>
      )}
    </ContentContainer>
  );
};

export default SectionContent;
