import React, { useState } from "react";
import { ScreenContainer, ContentContainer } from "./Elements";
import BackgroundSlider from "components/custom/BackgroundSlider";
import { donate1, donate2, donate3, donate4 } from "helpers/assets";
import { StepperTransition } from "helpers/transitions";
import { AnimatePresence } from "framer-motion";
import SectionContent from "components/custom/SectionContent";
const BACKGROUNDS = [donate1, donate2];

const DonateSection = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <ScreenContainer id="donate" className="container">
      <ContentContainer>
        <AnimatePresence mode="wait" key="donate">
          {activeIndex === 0 && (
            <SectionContent
              key="DONATE-FIRST"
              title="Venmo Donation"
              description="You can donate via our certified Venmo Charity account by clicking below."
              cta="Venmo Charity Donation"
              href="https://account.venmo.com/u/JohnWallFamilyFoundation"
              {...StepperTransition}
            />
          )}
          {activeIndex === 1 && (
            <SectionContent
              key="DONATE-SECOND"
              title="PayPal Donation"
              description="You can donate via our certified Paypal Charity account by clicking below."
              cta="PayPal Donation"
              href="https://www.paypal.com/donate/?hosted_button_id=9GE26UNM3ZSSS"
              {...StepperTransition}
            />
          )}
        </AnimatePresence>
      </ContentContainer>
      <BackgroundSlider
        backgrounds={BACKGROUNDS}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        from="DONATE"
      />
    </ScreenContainer>
  );
};

export default DonateSection;
