import styled from "styled-components";

export const JWContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10vh;
  margin-bottom: 10vh;
`;

export const JWImage = styled.img`
  width: 40%;
  @media screen and (max-width:768px) {
    width: 80%;
  }
`;
