 import React from "react";
import {
  AboutContainer,
  ContentContainer,
  ImageContainer,
  MissionContainer,
  Image,
  Details,
  MapFrame,
  ContactContainer,
} from "./Elements";
import { about } from "helpers/assets";
import SectionSubTitle from "components/custom/SectionSubTitle";
import SectionTitle from "components/custom/SectionTitle";
import ContactCard from "components/custom/ContactCard";
import { address, email, phone } from "helpers/assets";

const CONTACT_DETAIL = [
  {
    title: "Address",
    icon: address,
    detail: "JWFF PO Box 1213 Suitland, Maryland 20752",
    iconSize: "15%",
  },
  {
    title: "Email",
    icon: email,
    detail: "jwff@johnwallfamilyfoundation.com ",
    iconSize: "15%",
  },
];
const About = () => {
  return (
    <AboutContainer className='container' id='about'>
      <SectionSubTitle title='Who Are We?' />
      <SectionTitle title='About Us' />
      <ContentContainer>
        <ImageContainer>
          <Image src={about} alt='about' />
        </ImageContainer>
        <MissionContainer>
          <SectionTitle title='Our Mission' />
          <Details>
            The John Wall Family Foundation strives to improve the quality of
            life for disadvantaged families and impact the lives of at-risk
            youth through mentorship, education and physical activity.
          </Details>
        </MissionContainer>
      </ContentContainer>

      <ContactContainer>
        {CONTACT_DETAIL?.map((contact, index) => {
          return (
            <ContactCard
              title={contact?.title}
              icon={contact?.icon}
              detail={contact?.detail}
              iconSize={contact?.iconSize}
              key={`${contact.title}-${index}`}
            />
          );
        })}
      </ContactContainer>
    </AboutContainer>
  );
};

export default About;
