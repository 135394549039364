import React from "react";
import {
  CardContainer,
  Icon,
  IconContainer,
  DescriptionContainer,
  Title,
  Detail,
} from "./Elements";
const ContactCard = ({ icon, title, detail, iconSize }) => {
  return (
    <CardContainer>
      <IconContainer>
        <Icon src={icon} alt='icon' $size={iconSize} />
      </IconContainer>
      <DescriptionContainer>
        <Title>{title}</Title>
        <Detail>{detail}</Detail>
      </DescriptionContainer>
    </CardContainer>
  );
};

export default ContactCard;
