import React, { useState } from "react";
import { ScreenContainer, ContentContainer } from "./Elements";
import BackgroundSlider from "components/custom/BackgroundSlider";
import { event1, event2, event3 } from "helpers/assets";
import { StepperTransition } from "helpers/transitions";
import { AnimatePresence } from "framer-motion";
import SectionContent from "components/custom/SectionContent";
const BACKGROUNDS = [event1, event2, event3];

const EventsSection = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <ScreenContainer id="events" className="container">
      <ContentContainer>
        <AnimatePresence mode="wait" key="events">
          {activeIndex === 0 && (
            <SectionContent
              key="EVENTS-FIRST"
              title="Events"
              description="Volunteering is an incredibly important way to give back and help make a positive impact in the world. By volunteering for events like those organized by the JWFF you have the opportunity to make a difference. Additionally, volunteering for these types of events can also be a great way to gain valuable experience and skills, such as teamwork, communication, and leadership, that can be beneficial in both your personal and professional life. So if you're looking for a way to make a difference and give back, consider volunteering."
              cta="Volunteer"
              href="https://calendly.com/jwff"
              {...StepperTransition}
            />
          )}

          {activeIndex === 1 && (
            <SectionContent
              key="EVENTS-SECOND"
              title="Events"
              description="Volunteering is an incredibly important way to give back and help make a positive impact in the world. By volunteering for events like those organized by the JWFF you have the opportunity to make a difference. Additionally, volunteering for these types of events can also be a great way to gain valuable experience and skills, such as teamwork, communication, and leadership, that can be beneficial in both your personal and professional life. So if you're looking for a way to make a difference and give back, consider volunteering."
              cta="Volunteer"
              href="https://calendly.com/jwff"
              {...StepperTransition}
            />
          )}
          {activeIndex === 2 && (
            <SectionContent
              key="EVENTS-THIRD"
              title="Events"
              description="Volunteering is an incredibly important way to give back and help make a positive impact in the world. By volunteering for events like those organized by the JWFF you have the opportunity to make a difference. Additionally, volunteering for these types of events can also be a great way to gain valuable experience and skills, such as teamwork, communication, and leadership, that can be beneficial in both your personal and professional life. So if you're looking for a way to make a difference and give back, consider volunteering."
              cta="Join Us For Free"
              href="https://www.google.com"
              {...StepperTransition}
            />
          )}
        </AnimatePresence>
      </ContentContainer>
      <BackgroundSlider
        backgrounds={BACKGROUNDS}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        from="EVENTS"
      />
    </ScreenContainer>
  );
};

export default EventsSection;
