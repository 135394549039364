import React from "react";
import styled from "styled-components";

const Title = styled.label`
  font-size: 1.25rem;
  line-height: 1.25rem;
  color: ${(props) => props.theme.primary};
  font-family: "Aspira Demi", Arial, Helvetica, sans-serif;

  @media screen and (max-width: 1440px) {
    font-size: 0.9rem;
  }
`;
const SectionSubTitle = ({ title = "" }) => {
  return <Title>{title}</Title>;
};

export default SectionSubTitle;
