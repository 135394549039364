import styled from "styled-components";
import { Link as ScrollLink } from "react-scroll";

export const SidebarContainer = styled.div`
  display: none;
  @media screen and (max-width: 870px) {
    position: fixed;
    z-index: 999;
    width: 50%;
    height: 100%;
    background: ${(props) => props.theme.tertiary};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    top: 0%;
    right: 0%;
    transition: 0.3s ease;
    opacity: 100%;
    /* opacity: ${({ isOpen }) => (isOpen ? "100%" : "0%")}; */
    right: ${({ isOpen }) => (isOpen ? "50%" : "100%")};
  }
`;

export const LinkContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  height: 60%;
  width: 100%;
`;

export const NavLinks = styled(ScrollLink)`
  color: ${(props) => props.theme.other};
  font-family: "Aspira Demi";
  display: flex;
  align-items: center;
  text-decoration: none;
  white-space: nowrap;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  transition: all 0.05s ease-in;
  font-weight: 700;
  text-transform: capitalize;
  font-size: 0.9rem;
  letter-spacing: 1px;
  position: relative;
  transition: 0.3s;
`;
