import React from "react";
import { JWContainer, JWImage } from "./Elements";
import { JWLogo } from "helpers/assets";
const ImageSection = () => {
  return (
    <JWContainer>
      <JWImage src={JWLogo} alt="John Wall logo" />
    </JWContainer>
  );
};

export default ImageSection;
