import styled from "styled-components";
import { motion } from "framer-motion";
import { Link } from "react-scroll";

export const ContentContainer = styled(motion.div)`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 2vh;
  z-index: 2;
`;

export const SectionTitle = styled.h4`
  font-size: 1.5rem;
  font-weight: 600;
  color: ${(props) => props.theme.tertiary};
  font-family: "Aspira Demi", Arial, Helvetica, sans-serif;

  @media screen and (max-width: 768px) {
    font-size: 1.4rem;
    color: ${(props) => props.theme.other};
  }

  @media screen and (max-width: 480px) {
    font-size: 1.25rem;
  }
`;

export const SectionDescription = styled.p`
  font-family: "Aspira Regular", Arial, Helvetica, sans-serif;

  font-size: 1.15rem;
  line-height: 1.5rem;
  font-weight: 300;
  letter-spacing: 0.005em;
  @media screen and (max-width: 768px) {
    font-size: 1.4rem;
    color: #fff;
  }
`;

export const SectionCta = styled.a`
  outline: none;
  border: none;
  border-radius: 6px;
  padding: 0.6rem 1rem;
  text-align: center;
  cursor: pointer;
  background-color: ${(props) => props.theme.secondary};
  color: ${(props) => props.theme.other};
  text-decoration: none;
  font-family: "Aspira Demi", Arial, Helvetica, sans-serif;

  @media screen and (max-width: 768px) {
    color: ${(props) => props.theme.other};
  }

  @media screen and (max-width: 480px) {
    padding: 1rem 0.5rem;
    margin-top: 2vh;
    font-size: 0.75rem;
  }
`;

export const SectionLink = styled(Link)`
  outline: none;
  border: none;
  border-radius: 6px;
  padding: 0.6rem 1rem;
  text-align: center;
  cursor: pointer;
  background-color: ${(props) => props.theme.secondary};
  color: ${(props) => props.theme.other};
  text-decoration: none;
  font-family: "Aspira Demi", Arial, Helvetica, sans-serif;

  @media screen and (max-width: 768px) {
    color: ${(props) => props.theme.other};
  }

  @media screen and (max-width: 480px) {
    padding: 1rem 0.5rem;
    margin-top: 2vh;
    font-size: 0.75rem;
  }
`;

export const StepListContainer = styled.ol`
  height: max-content;
  width: max-content;
  text-align: start;
`;

export const StepList = styled.li`
  font-family: "Aspira Regular", Arial, Helvetica, sans-serif;

  font-size: 1.15rem;
  line-height: 1.5rem;
  font-weight: 300;
  letter-spacing: 0.005em;
  @media screen and (max-width: 768px) {
    font-size: 1.4rem;
    color: #fff;
  }
`;
