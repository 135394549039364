import React, { useState } from "react";
import { ScreenContainer, ContentContainer } from "./Elements";
import BackgroundSlider from "components/custom/BackgroundSlider";
import { community1, community2, community3 } from "helpers/assets";
import { StepperTransition } from "helpers/transitions";
import { AnimatePresence } from "framer-motion";
import SectionContent from "components/custom/SectionContent";
const BACKGROUNDS = [community1, community2, community3];

const CommunitySection = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <ScreenContainer id="community" className="container">
      <ContentContainer>
        <AnimatePresence mode="wait" key="community">
          {activeIndex === 0 && (
            <SectionContent
              key="COMMUNIT-FIRST"
              title="Community"
              description="We will always be a grass roots organization focused on the areas that need it the most. Join our team of community volunteers today to help support our upcoming events."
              cta="Volunteer"
              href="https://calendly.com/jwff"
              {...StepperTransition}
            />
          )}

          {activeIndex === 1 && (
            <SectionContent
              key="COMMUNIT-SECOND"
              title="Community"
              description="We will always be a grass roots organization focused on the areas that need it the most. Join our team of community volunteers today to help support our upcoming events."
              cta="Volunteer"
              href="https://calendly.com/jwff"
              {...StepperTransition}
            />
          )}
          {activeIndex === 2 && (
            <SectionContent
              key="COMMUNIT-THIRD"
              title="Community"
              description="We will always be a grass roots organization focused on the areas that need it the most. Join our team of community volunteers today to help support our upcoming events."
              cta="Volunteer"
              href="https://calendly.com/jwff"
              {...StepperTransition}
            />
          )}
        </AnimatePresence>
      </ContentContainer>
      <BackgroundSlider
        backgrounds={BACKGROUNDS}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        from="COMMUNTIY"
      />
    </ScreenContainer>
  );
};

export default CommunitySection;
