import styled from "styled-components";
import { Link as ScrollLink } from "react-scroll";
export const FooterContainer = styled.footer`
  min-height: 200px;
  height: 100%;
  max-height: 100%;
  width: 100vw;
  background-color: ${(props) => props.theme.tertiary};
  margin-top: 5vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 2rem 15vw 2rem 15vw;
  @media screen and (max-width: 1024px) {
    padding: 1rem 6vw 0 6vw;
  }

  @media screen and (max-width: 768px) {
    padding: 0.5rem 5vw;
  }
`;

export const Top = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width:768px) {
    flex-direction: column;
  }
`;

export const Bottom = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NavLogo = styled(ScrollLink)`
  color: ${(props) => props.theme.black};
  justify-self: flex-start;
  cursor: pointer;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "League Gothic", sans-serif;
  text-decoration: none;
  color: ${({ $scrollNav, theme }) =>
    $scrollNav ? theme.tertiary : theme.other};
  white-space: nowrap;
  & p {
    font-size: 2.6rem;
    line-height: 1.2rem;
  }
  & span {
    font-size: 1rem;
    font-family: "Aspira Light", Arial, Helvetica, sans-serif;
  }

  @media screen and (max-width: 480px) {
    & p {
      font-size: 2rem;
      line-height: 1rem;
    }
    & span {
      font-size: 0.8rem;
    }
  }
`;

export const Logo = styled.img`
  max-width: 10vh;
  height: auto;
  margin-right: 10px;
`;

export const IconsContainer = styled.div`
  flex: 0.35;
  display: flex;

  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 768px) {
    justify-content: center;
    width: 100%;
  }
`;
export const IconLink = styled.a``;
export const Image = styled.img`
  width: 30px;
  @media screen and (max-width: 768px) {
    margin-right: 1vw;
  }
`;

export const BottomText = styled.p`
  font-size: 0.85rem;
  color: ${(props) => props.theme.other};
  font-family: "Aspira Thin", Arial, Helvetica, sans-serif;
  margin-top: 5vh;
  max-width: 86ch;
  border: 0.5px solid rgba(255, 255, 255, 0.25);
  padding: 1.2rem;
  border-radius: 10px;
`;
