import styled from "styled-components";
import { motion } from "framer-motion";
export const HeroContainer = styled.div`
  min-height: 100vh;
  padding-top: 100px;
  height: 100vh;
  max-width: 100%;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  overflow: hidden;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    height: 100vh;
    padding-bottom: 1rem;
  }
`;

export const ContentContainer = styled(motion.div)`
  height: 80%;
  width: 100%;
  flex: 0.5;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: 768px) {
    height: 100%;
    z-index: 2;
    flex: 1;
  }
`;

export const MotionedContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Title = styled.h4`
  font-size: 5rem;
  font-weight: 600;
  color: ${(props) => props.theme.primary};
  font-family: "Unbounded", sans-serif;
  /* word-break: break-all; */
  text-align: center;
`;

export const StepListContainer = styled.ol`
  height: max-content;
  width: max-content;
  text-align: center;
`;

export const StepList = styled.li`
  font-size: 1.5rem;
  font-weight: 600;
  list-style: none;
  font-family: "Unbounded", sans-serif;
  letter-spacing: 1px;
  color: ${(props) => props.theme.secondary};

  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 0.8rem;
  }
`;

export const CrowdFundingBtn = styled.button`
  outline: none;
  border: none;
  border-radius: 6px;
  padding: 1rem 1rem;
  font-family: "Unbounded", sans-serif;
  cursor: pointer;
  background-color: ${(props) => props.theme.primary};
  color: ${(props) => props.theme.tertiary};
`;
