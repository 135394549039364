import React from "react";
import {
  FooterContainer,
  NavLogo,
  Logo,
  IconsContainer,
  IconLink,
  Image,
  Top,
  Bottom,
  BottomText,
} from "./Elements";
import {
  Facebook,
  Github,
  Gmail,
  Instagram,
  Linkedin,
  Tiktok,
  Youtube,
} from "helpers/assets";
import { logo } from "helpers/assets";
const Footer = () => {
  const SOCIAL_ICONS = [
    {
      icon: Tiktok,
      href: "https://www.tiktok.com",
      name: "Tiktok",
    },
    {
      icon: Gmail,
      href: "https://mail.google.com",
      name: "Gmail",
    },
    { icon: Github, href: "https://github.com", name: "Github" },
    {
      icon: Linkedin,
      href: "https://www.linkedin.com/company/jwffinc/",
      name: "Linkedin",
    },
    {
      icon: Youtube,
      href: "https://www.youtube.com/",
      name: "Youtube",
    },
    {
      icon: Facebook,
      href: "https://m.facebook.com",
      name: "Facebook",
    },
    {
      icon: Instagram,
      href: "https://www.instagram.com/",
      name: "Instagram",
    },
  ];
  return (
    <FooterContainer>
      <Top>
        <NavLogo to="home" smooth={true}>
          <Logo src={logo} alt="Logo" />
          <p>
            John Wall
            <br />
            <span>Family Foundation</span>
          </p>
        </NavLogo>

        <IconsContainer>
          {SOCIAL_ICONS?.map((icon, index) => {
            return (
              <IconLink
                key={`${icon.name}-${index}`}
                href={icon.href}
                target="_blank"
                referrerPolicy="no-referrer"
              >
                <Image src={icon.icon} />
              </IconLink>
            );
          })}
        </IconsContainer>
      </Top>
      <Bottom>
        <BottomText>
          
        </BottomText>
      </Bottom>
    </FooterContainer>
  );
};

export default Footer;
