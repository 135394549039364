import React from "react";
import HeroSection from "components/layout/HeroSection";
import CommunitySection from "components/layout/CommunitySection";
import EventsSection from "components/layout/EventsSection";
import DonationSection from "components/layout/DonationSection";
import ImageSection from "components/layout/ImageSection";
import About from "components/layout/AboutSection";
import STEEMSection from "components/layout/STEEMSection";
const LandingPage = () => {
  return (
    <>
      <HeroSection />
      <CommunitySection />
      <STEEMSection />
      <EventsSection />
      <DonationSection />
      <About />
      <ImageSection />
    </>
  );
};

export default LandingPage;
