import React from "react";
import styled from "styled-components";

const TitleTag = styled.h4`
  font-size: 2.9rem;
  color: ${(props) => props.theme.tertiary};
  font-family: "Aspira Bold", Arial, Helvetica, sans-serif;
  @media screen and (max-width: 1440px) {
    font-size: 2.25rem;
  }
  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;
const SectionTitle = ({ title = "" }) => {
  return <TitleTag>{title}</TitleTag>;
};

export default SectionTitle;
