import styled from "styled-components";

export const CardContainer = styled.div`
  height: 100%;
  width: 100%;
  flex: 0.3;

  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 768px) {
    justify-content: flex-start;
    margin-bottom: 2vh;
  }
`;

export const IconContainer = styled.div`
  height: 100%;
  width: 100%;
  flex: 0.3;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 768px) and (min-width: 480px) {
    flex: 0.1;
  }
`;

export const Icon = styled.img`
  width: 50%;
  @media screen and (max-width: 768px) and (min-width: 480px) {
    width: 65%;
  }
`;

export const DescriptionContainer = styled.div`
  height: 100%;
  width: 100%;
  flex: 0.7;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Title = styled.label`
  max-width: 100%;
  white-space: nowrap;
  font-family: "Aspira Bold", Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 800;
  letter-spacing: 0.005em;
  @media screen and (max-width: 768px) {
    font-size: 1.7rem;
  }
`;
export const Detail = styled.p`
  font-family: "Aspira Regular", Arial, Helvetica, sans-serif;
`;
