import React from "react";
import { SidebarContainer, LinkContainer, NavLinks } from "./elements";
const Sidebar = ({ isOpen, toggle }) => {
  return (
    <SidebarContainer isOpen={isOpen}>
      <LinkContainer>
        <NavLinks to='home' onClick={toggle} smooth>
          Home
        </NavLinks>
        <NavLinks to='community' onClick={toggle} smooth offset={-150}>
          Community
        </NavLinks>
        <NavLinks to='steems' onClick={toggle} smooth offset={-100}>
          BGCM™
        </NavLinks>

        <NavLinks to='events' onClick={toggle} smooth offset={-100}>
          Events
        </NavLinks>
        <NavLinks to='donate' onClick={toggle} smooth offset={-100}>
          Donate
        </NavLinks>
        <NavLinks to='about' onClick={toggle} smooth offset={-100}>
          About Us
        </NavLinks>
      </LinkContainer>
    </SidebarContainer>
  );
};

export default Sidebar;
