import React, { useState, useEffect } from "react";
import {
  NavbarContainer,
  Nav,
  NavLogo,
  Logo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
} from "./elements";
import { Twirl as Hamburger } from "hamburger-react";
import { logo } from "helpers/assets";
import { theme } from "theme";
const Navbar = ({ isOpen, toggle }) => {
  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 30) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  return (
    <NavbarContainer $scrollNav={scrollNav}>
      <Nav>
        <NavLogo to="home" smooth $scrollNav={scrollNav}>
          <Logo src={logo} alt="logo" />
          <p>
            John Wall
            <br />
            <span>Family Foundation</span>
          </p>
        </NavLogo>
        <MobileIcon>
          <Hamburger
            toggled={isOpen}
            toggle={toggle}
            size={20}
            duration={0.35}
            easing="ease-in"
            rounded
            $scrollNav={scrollNav}
            color={theme.tertiary}
          />
        </MobileIcon>
        <NavMenu>
          <NavItem>
            <NavLinks
              to="home"
              smooth
              activeClass="active"
              spy={true}
              offset={-500}
              $scrollNav={scrollNav}
              $color={theme.tertiary}
            >
              Home
            </NavLinks>
          </NavItem>

          <NavItem>
            <NavLinks
              to="community"
              smooth
              spy={true}
              offset={-50}
              activeClass="active"
              $scrollNav={scrollNav}
              $color={theme.tertiary}
            >
              Community
            </NavLinks>
          </NavItem>

          <NavItem>
            <NavLinks
              to="steems"
              smooth
              activeClass="active"
              spy={true}
              offset={-50}
              $scrollNav={scrollNav}
              $color={theme.tertiary}
            >
              BGCM™
            </NavLinks>
          </NavItem>

          <NavItem>
            <NavLinks
              to="events"
              smooth
              activeClass="active"
              spy={true}
              offset={-50}
              $scrollNav={scrollNav}
              $color={theme.tertiary}
            >
              Events
            </NavLinks>
          </NavItem>

          <NavItem>
            <NavLinks
              to="donate"
              smooth
              activeClass="active"
              spy={true}
              offset={-50}
              $scrollNav={scrollNav}
              $color={theme.tertiary}
            >
              Donate
            </NavLinks>
          </NavItem>

          <NavItem>
            <NavLinks
              to="about"
              smooth
              activeClass="active"
              spy={true}
              offset={-50}
              $scrollNav={scrollNav}
              $color={theme.primary}
            >
              About Us
            </NavLinks>
          </NavItem>
        </NavMenu>
      </Nav>
    </NavbarContainer>
  );
};

export default Navbar;
